import React, { Component } from 'react';
import { DatePicker,Button,Checkbox,Col, Input, message, Row, Select, Tooltip } from 'antd';
import { generateKey, base64_encode, base64_decode } from '../Utilities/Base64';
import axios from 'axios';
import back from '../image/back.png'
import { Redirect} from 'react-router-dom'
import '../Modal/ModalStyle.css'
import {EditOutlined} from '@ant-design/icons'; 
import Modal from 'react-modal'
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //loading
            loadingGetUser: false,
            loadingBalcklisted: false,
            loadingSubscriber: false,
            display_all_filter: 'block',
            user_connected: null,
            //liste
            liste_country: ['AU','BEFR','BENL','DE','ES', 'FR', 'IT', 'MEX', 'NL','NO', 'PL','SE', 'SG', 'UK'],
            liste_data_checked: [],
            liste_to_update: [],
            liste_result: [],
            filter: [{"filterid" : null, "filter": "", "value" : ""}],
            //data
            div_title: "DWH Manager",
            country: ["FR"],
            liste_country_selected: [],
            blacklist_value: [],
            unsub_value: [],
            email: null,
            mobile: null,
            phone: null,
            firstname: null,
            lastname: null,
            //updated data
            update_dwh_id: null,
            update_database_name: null,
            update_email: null,
            update_firstname: null,
            update_lastname: null,
            update_civility: null,
            update_zipcode: null,
            update_city: null,
            update_adresse_1: null,
            update_adresse_2: null,
            update_adresse_3: null,
            update_adresse_4: null,
            update_fixe: null,
            update_mobile: null,
            update_birthdate: null,
            update_collecte_ip: null,
            update_collecte_date: null,
            update_vendor: null,
            update_recency: null,


            //display:
            displayGetUser: 'block',
            displayResultGetUser: 'block',
            isLogin: true,
            check_all: false,
            show_modal_update: false,
            show_modal_blacklist: false,
            show_modal_unsub: false,
            
        }
    }

    componentDidMount() {
        // if(sessionStorage.getItem(base64_encode('session')) === null){
        //     sessionStorage.clear()
        //     this.setState({
        //         isLogin: false
        //     })
        // }
    }

    onGetUser(){
        var liste_result = []
        liste_result.map(item => (
            item['checked'] = false
        ))
        this.setState({loadingGetUser: true})
        if(this.state.email === null && this.state.mobile === null && this.state.phone === null){
            message.warning('One of filter need to be filled to do a search')
            this.setState({loadingGetUser: false})
            return null
        }else{
            var fd = {}
            fd['country'] = this.state.country
            var data_filter = {}
            if(this.state.email !== null && (this.state.email).length !== 0){
                data_filter['email'] = this.state.email
            }
            if(this.state.phone !== null && (this.state.phone).length !== 0){
                data_filter['phone'] = this.state.phone
            }
            if(this.state.mobile !== null && (this.state.mobile).length !== 0){
                data_filter['mobile'] = this.state.mobile
            }
            if(this.state.firstname !== null && (this.state.firstname).length !== 0){
                data_filter['firstname'] = this.state.firstname
            }
            if(this.state.lastname !== null && (this.state.lastname).length !== 0){
                data_filter['lastname'] = this.state.lastname
            }
            
            fd['filter'] = data_filter
            if(Object.keys(data_filter).length> 0)
            {
                axios.post(process.env.REACT_APP_ENDPOINT_GET, fd)
                .then(res => {
                    console.log(res.data)
                    if(res.data[0]['status'] === 'success'){
                        res.data.splice(0, 1)
                        this.setState({liste_result:  res.data, loadingGetUser: false, div_title: "Update subscriber"})
                    }else{
                        message.info('No subscriber match for the search')
                        this.setState({loadingGetUser: false, liste_result: []})
                    }
                })
            }else{
                this.setState({loadingGetUser: false})
                message.info("missing value parameter")
            }
        }
    }

    onCheckBoxAllChecked(checked){
        var check_data = []
        this.state.liste_result && this.state.liste_result.map(item => {
            item['checked'] = checked
            check_data.push(item)
            return null
        })
        this.setState({liste_result: check_data,check_all: checked})
    }

    onUpdateCheckedStatus(dwh_id, checked){
        var data = []
        this.state.liste_result && this.state.liste_result.map(item => {
            if(item['dwh_contact_id'] === dwh_id){
                item['checked'] = checked
                data.push(item)
            }else{
                data.push(item)
            }
            return
        }
        )
        this.setState({liste_result: data})
    }

    onUpdateSelectedCountry(country){
        var status_checked = false
        var country_selected = []
        country.map(item => {
            if(item === 'all'){
                status_checked = true
                return null
            }
        })
        if(status_checked){
            this.state.liste_country.map(item => {
                country_selected.push(item)
            })
            this.setState({country: ['all'], liste_country_selected: country_selected}) 
        }else{
            this.setState({country: country, liste_country_selected: country}) 
        }
        
    }

    onCloseModalUpdate(){
        this.setState({
            show_modal_update: false,
            update_dwh_id: null,
            update_database_name: null,
            update_email: null,
            update_firstname: null,
            update_lastname: null,
            update_civility: null,
            update_zipcode: null,
            update_city: null,
            update_adresse_1: null,
            update_adresse_2: null,
            update_adresse_3: null,
            update_adresse_4: null,
            update_fixe: null,
            update_mobile: null,
            update_birthdate: null,
            update_collecte_ip: null,
            update_collecte_date: null,
            update_vendor: null,
            update_recency: null
        })
    }

    onUpdateSubscribers(){
        this.setState({loadingGetUser: true })
        var country = this.state.country === null ? 'FR' : this.state.country 
        var birthdate = this.state.update_birthdate !== null ? (this.state.update_birthdate).format('YYYY-MM-DD').toString()  : ''
        var recency = this.state.update_recency !== null  ? (this.state.update_recency).format('YYYY-MM-DD').toString()  : ''
        var collecte_date = this.state.update_collecte_date !== null ? (this.state.update_collecte_date).format('YYYY-MM-DD').toString()  : ''
        var all_data = new FormData()
        all_data.append('dwh_id', this.state.update_dwh_id)
        all_data.append('database_name', this.state.update_database_name)
        all_data.append('database_country', country)
        all_data.append('email', this.state.update_email)
        all_data.append('firstname',this.state.update_firstname)
        all_data.append('lastname', this.state.update_lastname)
        all_data.append('zipcode', this.state.update_zipcode)
        all_data.append('city', this.state.update_city)
        all_data.append('adresse_1', this.state.update_adresse_1)
        all_data.append('adresse_2', this.state.update_adresse_2)
        all_data.append('adresse_3', this.state.update_adresse_3)
        all_data.append('adresse_4', this.state.update_adresse_4)
        all_data.append('fixe', this.state.update_fixe)
        all_data.append('mobile', this.state.update_mobile)
        all_data.append('collect_ip', this.state.update_collecte_ip)
        all_data.append('recency', recency)
        all_data.append('birthdate', birthdate)
        all_data.append('vendor', this.state.update_vendor)
        all_data.append('civility', this.state.update_civility)
        all_data.append('collecte_date', collecte_date)
        all_data.append('connected_user', base64_decode(sessionStorage.getItem(base64_encode('userconnected'))))
        
        axios.post(process.env.REACT_APP_ENDPOINT_UPDATE, all_data)
        .then(res => {
            switch(res.data['status']){
                case 'user updated again':
                    message.success('Requests to update user has been send')
                    break

                case 'user updated':
                    message.success('Requests to update user has been send', 2)
                    break

                case 'success':
                    message.success('Requests to update user has been send', 2)
                    break

                case 'error':
                    message.warning('Failed updating user !!!')
                    break

                default:
                    message.info("Some issue at the api " , 2)
                    break
            }
            setTimeout(() => {
                window.location.reload()
            }, 2000)

            // this.onGetUser()
            // this.setState({
            //     liste_result: [], 
            //     loadingGetUser: false, 
            //     div_title: "DWH Manager", 
            //     displayGetUser: 'block',
            //     displayResultGetUser: 'block', 
            //     show_modal_update: false,
            //     update_dwh_id: null,
            //     update_database_name: null,
            //     update_email: null,
            //     update_firstname: null,
            //     update_lastname: null,
            //     update_civility: null,
            //     update_zipcode: null,
            //     update_city: null,
            //     update_adresse_1: null,
            //     update_adresse_2: null,
            //     update_adresse_3: null,
            //     update_adresse_4: null,
            //     update_fixe: null,
            //     update_mobile: null,
            //     update_birthdate: null,
            //     update_collecte_ip: null,
            //     update_collecte_date: null,
            //     update_vendor: null,
            //     update_recency: null,
            // })
        })

    }

    onBlacklistedOrsubscription(typeAction){
        typeAction === 'blacklist' ? this.setState({loadingBalcklisted: true}) : this.setState({loadingSubscriber: true})
        if (typeAction === 'blacklist') {
            if ((this.state.blacklist_value).length === 0){
                message.warning('Choose at least one blacklist channel !!!', 2)
                this.setState({loadingBalcklisted: false})
                return null
            }
        }

        if (typeAction === 'unsub') {
            if ((this.state.unsub_value).length === 0){
                message.warning('Choose at least one subscription channel !!!', 2)
                this.setState({loadingSubscriber: false})
                return null
            }
        }
        var url_action = typeAction === "blacklist" ? process.env.REACT_APP_ENDPOINT_BLACKLIST: process.env.REACT_APP_ENDPOINT_UNSUB
        var checked_list = []
        
        for(var i = 0; i< this.state.liste_result.length; i++){
            if(typeof this.state.liste_result[i] !== 'undefined'){
                if(this.state.liste_result[i]['checked'] === true){
                    checked_list.push(this.state.liste_result[i]['dwh_contact_id'])
                }
            }
        }

        var data_send = typeAction === 'blacklist' ? {'user_connected' : base64_decode(sessionStorage.getItem(base64_encode('userconnected'))),'id_dwh': checked_list, [typeAction+'_type']: JSON.stringify(this.state.blacklist_value)} : {'user_connected' : base64_decode(sessionStorage.getItem(base64_encode('userconnected'))),'id_dwh': checked_list, [typeAction+'_type']: JSON.stringify(this.state.unsub_value)}
        axios.post(url_action, data_send)
        .then(res => {
            typeAction === 'blacklist' ? message.success('Blacklist request was send to the dwh api', 3) : message.success('Unsub request was send to the dwh api', 3)
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        })
        
        
    }

    onCloseModalBlacklist(){
        this.setState({
            show_modal_blacklist: false,
            display_all_filter: 'block',
            blacklist_value: []
        })
    }

    onCloseModalUnsub(){
        this.setState({
            show_modal_unsub: false,
            display_all_filter: 'block',
            unsub_value: []
        })
    }

    onBlacklistClicked(){
        var checked_list = []
        for(var i = 0; i< this.state.liste_result.length; i++){
            if(typeof this.state.liste_result[i] !== 'undefined'){
                if(this.state.liste_result[i]['checked'] === true){
                    checked_list.push(this.state.liste_result[i]['dwh_contact_id'])
                }
            }
        }
        if(checked_list.length == 0){
            message.warning("No contact selectionned !!!",1)
            return null
        }else{
            this.setState({show_modal_blacklist: true})
        }
    }

    onRemoveClicked(){
        var checked_list = []
        for(var i = 0; i< this.state.liste_result.length; i++){
            if(typeof this.state.liste_result[i] !== 'undefined'){
                if(this.state.liste_result[i]['checked'] === true){
                    checked_list.push(this.state.liste_result[i]['dwh_contact_id'])
                }
            }
        }
        if(checked_list.length == 0){
            message.warning("No contact selectionned !!!", 1)
            return null
        }else{
            this.setState({show_modal_unsub: true})
        }
    }

    render() {
        // if(this.state.isLogin === false){
        //     return (<Redirect to={{
        //         pathname: '/'
        //     }} />)
        // }

        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
                position: 'top'
            }
        }
        return (
            <div style={{background: '#fff', height: '100vh',border: '1px solid #FFF'}}>
                <div style={{width: '100%', margin: '0 auto', marginTop: '0%', height: 'auto', position: 'relative', boxShadow: '0 5px 10px rgb(1 1 1 / 20%)', borderRadius: 10, boxSizing: 'border-box', minWidth: 640}}>
                    {/* <div style={{borderTopLeftRadius: 10, borderTopRightRadius: 10, height: 60, background: '#243b55', textAlign: 'center', padding: 12}}>
                        <h3 style={{ color: '#fff'}}>{this.state.div_title}</h3>
                    </div> */}
                    <div className='content' style={{padding: 0, display: `${this.state.displayGetUser}`}}>
                        <Row style={{padding: 3}}>
                            <Col span={1} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Country
                            </Col>
                            <Col span={2}>
                                <Select
                                    size="medium"
                                    mode='multiple'
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: '90%', color: '#192a56', textAlign: 'center', fontWeight: 800 }}
                                    value={this.state.country}
                                    onChange={(value) => {
                                        this.onUpdateSelectedCountry(value)
                                    }}
                                >
                                    <Option key="12456" value="all">---All---</Option>
                                    {
                                        this.state.liste_country && this.state.liste_country.map(item => {
                                            var key = generateKey()
                                            return(
                                                <Option  key={key} value={item}>{item}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={1} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                E-mail
                            </Col>
                            <Col span={4}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56', fontWeight: 600 }}
                                    value={this.state.email}
                                    onChange={(e) => {this.setState({email: e.target.value})}}
                                    placeholder='email'
                                />
                            </Col>

                            <Col span={2} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Firstname
                            </Col>
                            <Col span={2}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56',fontWeight: 600 }}
                                    value={this.state.firstname}
                                    onChange={(e) => {this.setState({firstname: e.target.value})}}
                                />
                            </Col>
                            <Col span={2} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Lastname
                            </Col>
                            <Col span={2}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56',  fontWeight: 600 }}
                                    value={this.state.lastname}
                                    onChange={(e) => {this.setState({lastname: e.target.value})}}
                                />
                            </Col>
                            <Col span={1} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Mobile
                            </Col>
                            <Col span={2}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56', fontWeight: 600 }}
                                    value={this.state.mobile}
                                    onChange={(e) => {this.setState({mobile: e.target.value})}}
                                />
                            </Col>
                            <Col span={3} style={{textAlign: 'center'}}>
                                <Button
                                    loading={this.state.loadingGetUser}
                                    className='btn btn-success'
                                    style={{width: '80%', fontSize: 15, fontWeight: 600, textAlign: 'center'}}
                                    onClick={this.onGetUser.bind(this)}
                                >
                                    SEARCH
                                </Button>
                            </Col>
                            
                        </Row>
                        {/* <Row style={{padding: 10}}>
                            <Col span={4}></Col>
                            <Col span={4} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                E-mail
                            </Col>
                            <Col span={10}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56', fontWeight: 600 }}
                                    value={this.state.email}
                                    onChange={(e) => {this.setState({email: e.target.value})}}
                                />
                            </Col>
                        </Row>
                        <Row style={{padding: 10}}>
                            <Col span={4}></Col>
                            <Col span={4} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Firstname
                            </Col>
                            <Col span={10}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56',fontWeight: 600 }}
                                    value={this.state.firstname}
                                    onChange={(e) => {this.setState({firstname: e.target.value})}}
                                />
                            </Col>
                        </Row>
                        <Row style={{padding: 10}}>
                            <Col span={4}></Col>
                            <Col span={4} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Lastname
                            </Col>
                            <Col span={10}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56',  fontWeight: 600 }}
                                    value={this.state.lastname}
                                    onChange={(e) => {this.setState({lastname: e.target.value})}}
                                />
                            </Col>
                        </Row>
                        <Row style={{padding: 10}}>
                            <Col span={4}></Col>
                            <Col span={4} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Mobile
                            </Col>
                            <Col span={10}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56', fontWeight: 600 }}
                                    value={this.state.mobile}
                                    onChange={(e) => {this.setState({mobile: e.target.value})}}
                                />
                            </Col>
                        </Row>
                        <Row style={{padding: 10}}>
                            <Col span={4}></Col>
                            <Col span={4} style={{textAlign: 'center', padding: 2, fontSize: '1.2em', fontFamily: 'sans-serif'}}>
                                Phone
                            </Col>
                            <Col span={10}>
                                <Input
                                    size='medium'
                                    style={{ width: '100%', color: '#192a56', fontWeight: 600 }}
                                    value={this.state.phone}
                                    onChange={(e) => {this.setState({phone: e.target.value})}}
                                />
                            </Col>
                        </Row>
                        <Row style={{padding: 5}}>
                            <Col span={4}></Col>
                            <Col span={4}></Col>
                            <Col span={10} style={{textAlign: 'center'}}>
                                <Button
                                    loading={this.state.loadingGetUser}
                                    className='btn btn-success'
                                    style={{width: '60%', height: 50, fontSize: 18, fontWeight: 700}}
                                    onClick={this.onGetUser.bind(this)}
                                >
                                    SEARCH
                                </Button>
                            </Col>
                        </Row> */}
                    </div>
                    <div className='content' style={{padding: 15, display: `${this.state.displayResultGetUser}`, height: '90vh', overflowX: 'hidden', overflowY: 'auto'}}>
                        <div>
                            <div style={{width: '100%'}}>
                                <div style={{width: '100%', height: 'auto', overflowY: 'auto', maxHeight: '65vh'}}>
                                    <table className='table table-bordered' style={{width: '100%', height: '100%', border: '1px solid #23bcbe'}}>
                                        <thead className=''>
                                            <tr style={{background: "#23bcbe", color: "#fff", position: 'relative'}}>
                                                <th style={{textAlign: 'center'}}>
                                                    <Checkbox
                                                        checked={this.state.check_all}
                                                        onChange={(e) => {
                                                            this.onCheckBoxAllChecked(e.target.checked)
                                                        }}
                                                    />
                                                </th>
                                                {/* <th style={{textAlign: 'center'}}>Id</th> */}
                                                <th style={{textAlign: 'center'}}>Base</th>
                                                <th style={{textAlign: 'center'}}>Vendor</th>
                                                <th style={{textAlign: 'center'}}>Firstname</th>
                                                <th style={{textAlign: 'center'}}>Lastname</th>
                                                <th style={{textAlign: 'center'}}>Zipcode</th>
                                                <th style={{textAlign: 'center'}}>Ville</th>
                                                <th style={{textAlign: 'center'}}>Email</th>
                                                <th style={{textAlign: 'center'}}>Phone</th>
                                                <th style={{textAlign: 'center'}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.liste_result && this.state.liste_result.map(item => {
                                                    var key = generateKey()
                                                    return(
                                                        <tr key={key}>
                                                            <td style={{textAlign: 'center', width: '5%'}}>
                                                                <Checkbox
                                                                    checked={item['checked']}
                                                                    onChange={(e) => {
                                                                        this.onUpdateCheckedStatus(item['dwh_contact_id'], e.target.checked)
                                                                    }}
                                                                />
                                                            </td>
                                                            {/* <td style={{textAlign: 'center'}}>{item['dwh_contact_id']}</td> */}
                                                            <td style={{textAlign: 'center', width: '15%'}}>{item['database_name']}</td>
                                                            <td style={{textAlign: 'center', width: '10%'}}>{item['vendor']}</td>
                                                            <td style={{textAlign: 'center', width: '10%'}}>{item['firstname']}</td>
                                                            <td style={{textAlign: 'center', width: '10%'}}>{item['lastname']}</td>
                                                            <td style={{textAlign: 'center', width: '10%'}}>{item['zipcode']}</td>
                                                            <td style={{textAlign: 'center', width: '10%'}}>{item['city']}</td>
                                                            <td style={{textAlign: 'center', width: '15%'}}>{item['email']}</td>
                                                            <td style={{textAlign: 'center', width: '10%'}}>{item['mobile']}</td>
                                                            <td style={{textAlign: 'center', width: '5%'}}>
                                                                <Row>
                                                                    <Col span={24} style={{textAlign: 'center'}}>
                                                                        <Tooltip title="update" placement="bottom">
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                style={{height: 25, width: 25, padding: 0, marginBottom: 10 }}
                                                                                onClick={() => {
                                                                                    var date_birth = null
                                                                                    var date_recency = null
                                                                                    if(item['birthdate'] !== null){
                                                                                        date_birth = moment(item['birthdate'], 'YYYY-MM-DD')
                                                                                    }
                                                                                    if(item['recency'] !== null){
                                                                                        date_recency = moment(item['recency'], 'YYYY-MM-DD')
                                                                                    }
                                                                                this.setState({
                                                                                        show_modal_update: true,
                                                                                        update_dwh_id: item['dwh_contact_id'],
                                                                                        update_database_name: item['database_name'],
                                                                                        update_email: item['email'],
                                                                                        update_firstname: item['firstname'],
                                                                                        update_lastname: item['lastname'],
                                                                                        update_civility: item['civility'],
                                                                                        update_zipcode: item['zipcode'],
                                                                                        update_city: item['city'],
                                                                                        update_adresse_1: item['address_1'],
                                                                                        update_adresse_2: item['address_2'],
                                                                                        update_adresse_3: item['address_3'],
                                                                                        update_adresse_4: item['address_4'],
                                                                                        update_fixe: item['telephone_fixe'],
                                                                                        update_mobile: item['mobile'],
                                                                                        update_birthdate: date_birth ,
                                                                                        //update_birthdate:'',
                                                                                        update_collecte_ip: item['collect_ip'],
                                                                                        update_vendor: item['vendor'],
                                                                                        update_recency: date_recency
                                                                                }) 
                                                                                }}
                                                                            >
                                                                                <EditOutlined style={{ color: '#FFF', fontSize: 15, marginTop: '0px', verticalAlign: 'baseline'}} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </Col>
                                                                </Row>
                                                                
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                            <div style={{height: 'auto'}}>
                                <Row style={{padding: 15}}>
                                    <Col span={2}></Col>
                                    <Col span={10} style={{textAlign: 'center'}}>
                                        <span style={{fontWeight: 600, color: '#000099'}}>
                                            <strong>Blacklist</strong> selected subscriber(s)
                                        </span>
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                            className='btn btn-warning'
                                            style={{width: '75%', margin: '0 auto', color: '#fff', fontWeight: 800, padding: 5}}
                                            onClick={this.onBlacklistClicked.bind(this)}
                                        >
                                            BLACKLIST
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{padding: 15}}>
                                    <Col span={2}></Col>
                                    <Col span={10} style={{textAlign: 'center'}}>
                                        <span style={{fontWeight: 600, color: '#000099'}}>
                                            <strong>Unsubscribe</strong> selected subscriber(s)
                                        </span>
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                            className='btn btn-danger'
                                            style={{width: '75%', margin: '0 auto', color: '#fff', fontWeight: 800, padding: 5}}
                                            onClick={this.onRemoveClicked.bind(this)}
                                        >
                                            REMOVE
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    id="updateSubscriber"
                    isOpen={this.state.show_modal_update}
                    onRequestClose={this.onCloseModalUpdate.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ background: '#243B55', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}>
						<h3 style={{ color: "#fff", padding: 5 }}>SUBSCRIBER INFO</h3>
					</div>
					<span style={{ color: "#C23616", marginTop: '-61px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalUpdate.bind(this)}>
						X
					</span>
                    <div style={{width: '100%', padding: 0}}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Email</span></Col>
                                    <Col span={14}>
                                        <Input 
                                            size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_email}
                                            onChange={(e) => {this.setState({update_email: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Firstname</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_firstname}
                                            onChange={(e) => {this.setState({update_firstname: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Lastname</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            onChange={(e) => {this.setState({update_lastname: e.target.value})}}
                                            value={this.state.update_lastname}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Civility</span></Col>
                                    <Col span={14}>
                                        <Select
                                            size="medium"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '90%', color: '#192a56', textAlign: 'center', fontWeight: 800 }}
                                            value={this.state.update_civility}
                                            onChange={(value) => {
                                                this.setState({
                                                    update_civility: value
                                                })
                                            }}
                                        >
                                            <Option value="Mme" key={1}>Mme</Option>
                                            <Option value="M" key={1}>Mr</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Zipcode</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_zipcode}
                                            onChange={(e) => {this.setState({update_zipcode: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>City</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_city}
                                            onChange={(e) => {this.setState({update_city: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Phone</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_mobile}
                                            onChange={(e) => {this.setState({update_mobile: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Fixe</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_fixe}
                                            onChange={(e) => {this.setState({update_fixe: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Birthdate</span></Col>
                                    <Col span={14}>
                                        <DatePicker
                                            format={dateFormat} 
                                            style={{ margin: '0 auto', width: '100%', textAlign: 'center'}}
                                            value={this.state.update_birthdate}
                                            onChange={(a,b) => {
                                                this.setState({
                                                    update_birthdate: a
                                                })
                                            }} 
									    />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>DWH ID</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_dwh_id}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12}>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Database</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_database_name}
                                            onChange={(e) => {this.setState({update_database_name: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Database country</span></Col>
                                    <Col span={14}>
                                        <Select
                                            size="medium"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '90%', color: '#192a56', textAlign: 'center', fontWeight: 800 }}
                                            value={this.state.country}
                                            onChange={(value) => {
                                                this.setState({
                                                    country: value
                                                })
                                            }}
                                        >
                                            {
                                                this.state.liste_country && this.state.liste_country.map(item => {
                                                    var key = generateKey()
                                                    return(
                                                        <Option  key={key} value={item}>{item}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Vendor</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_vendor}
                                            onChange={(e) => {this.setState({update_vendor: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Adresse 1</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_adresse_1}
                                            onChange={(e) => {this.setState({update_adresse_1: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Adresse 2</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_adresse_2}
                                            onChange={(e) => {this.setState({update_adresse_2: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Adresse 3</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_adresse_3}
                                            onChange={(e) => {this.setState({update_adresse_3: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Adresse 4</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_adresse_4}
                                            onChange={(e) => {this.setState({update_adresse_4: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Recency date</span></Col>
                                    <Col span={14}>
                                        <DatePicker
                                            format={dateFormat} 
                                            style={{ margin: '0 auto', width: '100%', textAlign: 'center'}}
                                            value={this.state.update_recency}
                                            onChange={(a,b) => {
                                                this.setState({
                                                    update_recency: a
                                                })
                                            }} 
									    />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Collected IP</span></Col>
                                    <Col span={14}>
                                        <Input size='medium' 
                                            style={{ width: '100%', color: '#243B55', textAlign: 'center', fontWeight: 600 }}
                                            value={this.state.update_collecte_ip}
                                            onChange={(e) => {this.setState({update_collecte_ip: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={10} style={{padding: 3, textAlign: 'center'}}><span>Collected date</span></Col>
                                    <Col span={14}>
                                        <DatePicker
                                            format={dateFormat} 
                                            style={{ margin: '0 auto', width: '100%', textAlign: 'center'}}
                                            value={this.state.update_collecte_date}
                                            onChange={(a,b) => {
                                                this.setState({
                                                    update_collecte_date: a
                                                })
                                            }} 
									    />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <br/> */}
                        <Row>
                            <Col span={4}></Col>
                            <Col span={8}>
                                <Button
                                    className='btn btn-secondary'
                                    style={{width: '75%', margin: '0 auto', height: 50}}
                                    onClick={this.onCloseModalUpdate.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button
                                    loading={this.state.loadingGetUser}
                                    className='btn btn-primary'
                                    style={{width: '75%', margin: '0 auto', height: 50}}
                                    onClick={this.onUpdateSubscribers.bind(this)}
                                >
                                    UPDATE
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="blacklist"
                    isOpen={this.state.show_modal_blacklist}
                    onRequestClose={this.onCloseModalBlacklist.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ background: '#E0A800', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}>
						<h3 style={{ color: "#fff", padding: 5 }}>BLACKLIST SUBSCRIBER</h3>
					</div>
					<span style={{ color: "#C23616", marginTop: '-61px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalBlacklist.bind(this)}>
						X
					</span>
                    <div>
                        <Row style={{padding: 8}}>
                            <Col span={8} style={{textAlign: 'center', padding: 5}}>
                                <span>Blacklist subscriber by</span>
                            </Col>
                            <Col span={14}>
                                <Select
                                    mode="multiple"
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: '100%', color: '#192a56', fontWeight: 700,textAlign: 'center' }}
                                    value={this.state.blacklist_value}
                                    onChange={(value) => {
                                        if(value.includes('all')){
                                            this.setState({blacklist_value: ['email', 'sms', 'telemkt_fixe', 'telemkt_mobile', 'postal'], display_all_filter: 'none'})
                                        }else{
                                            this.setState({
                                                blacklist_value: value,
                                                display_all_filter: 'block'
                                            })
                                        }
                                        
                                    }}
                                >
                                    <Option value="all" style={{display: `${this.state.display_all_filter}`}}>all</Option>
                                    <Option value="email">email</Option>
                                    <Option value="sms">sms</Option>
                                    <Option value="telemkt_fixe">télémarketing fixe</Option>
                                    <Option value="telemkt_mobile">télémarketing mobile</Option>
                                    <Option value="postal">postal</Option>
                                </Select>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={8}></Col>
                            <Col span={13} style={{textAlign: 'center'}}>
                                <Button
                                    loading={this.state.loadingBalcklisted}
                                    className='btn btn-warning'
                                    style={{width: '75%', height: 50}}
                                    onClick={() => {
                                        this.onBlacklistedOrsubscription('blacklist')
                                    }}
                                >
                                    BLACKLIST
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="unsub"
                    isOpen={this.state.show_modal_unsub}
                    onRequestClose={this.onCloseModalUnsub.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ background: '#c82333', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}>
						<h3 style={{ color: "#fff", padding: 5 }}>UNSUB SUBSCRIBER</h3>
					</div>
					<span style={{ color: "#fff", marginTop: '-61px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalUnsub.bind(this)}>
						X
					</span>
                    <div>
                        <Row style={{padding: 8}}>
                            <Col span={8} style={{textAlign: 'center', padding: 5}}>
                                <span>Unsub subscriber by</span>
                            </Col>
                            <Col span={14}>
                                <Select
                                    mode="multiple"
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: '100%', color: '#192a56', fontWeight: 700,textAlign: 'center' }}
                                    value={this.state.unsub_value}
                                    onChange={(value) => {
                                        if(value.includes('all')){
                                            this.setState({unsub_value: ['email', 'sms', 'telemkt_fixe', 'telemkt_mobile', 'postal'], display_all_filter: 'none'})
                                        }else{
                                            this.setState({
                                                unsub_value: value,
                                                display_all_filter: 'block'
                                            })
                                        }
                                    }}
                                >
                                    <Option value="all" style={{display: `${this.state.display_all_filter}`}}>all</Option>
                                    <Option value="email">email</Option>
                                    <Option value="sms">sms</Option>
                                    <Option value="telemkt_fixe">télémarketing fixe</Option>
                                    <Option value="telemkt_mobile">télémarketing mobile</Option>
                                    <Option value="postal">postal</Option>
                                </Select>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={8}></Col>
                            <Col span={13} style={{textAlign: 'center'}}>
                                <Button
                                    loading={this.state.loadingSubscriber}
                                    className='btn btn-danger'
                                    style={{width: '75%', height: 50}}
                                    onClick={() => {
                                        this.onBlacklistedOrsubscription('unsub')
                                    }}
                                >
                                    UNSUSCRIBE
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        );
    }
    
}

export default Home;