import React, { Component } from 'react';
import { Tabs } from 'antd';
import Home from './Home';
import Webservices from './Webservices';
import { base64_encode } from '../Utilities/Base64';
import { Redirect} from 'react-router-dom'


const { TabPane } = Tabs;
class Container extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isLogin: true
        }

    }

    componentDidMount() {
        if(sessionStorage.getItem(base64_encode('session')) === null){
            sessionStorage.clear()
            this.setState({
                isLogin: false
            })
        }
    }

    render() {
        if(this.state.isLogin === false){
            return (<Redirect to={{
                pathname: '/'
            }} />)
        }
        return (
            <div style={{ width: '100%', height: '100vh', background: "#fff" }}>
                <Tabs
                    tabPosition="top"
                    style={{height: '100%', background: 'aliceblue' }}
                    defaultActiveKey="1"
                >
                    <TabPane
                        tab={<div style={{ width: 400, textAlign: 'center', fontWeight: 600, color: '#3C8DBC', fontSize: 18 }}><span>Contact Management (DWH and Routeur)</span></div>}
                        key="1"
                    >
                        <Home/>
                    </TabPane>
                    <TabPane
                        tab={<div style={{ width: 400, textAlign: 'center', fontWeight: 600, color: '#3C8DBC', fontSize: 18 }}><span>Webservices</span></div>}
                        key="2"
                    >
                        <Webservices />
                    </TabPane>

                </Tabs>
            </div>
        );
    }
}


export default Container;