import React, { Component } from 'react';
import { Collapse, Button, Checkbox, Col, Input, message, Row, Select, Tooltip } from 'antd';
import axios from 'axios';
import { base64_encode, base64_decode } from '../Utilities/Base64';
import { WarningOutlined } from '@ant-design/icons';

const { Option } = Select
class Internal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endpoint:"https://api.kontikimedia.com:5007/api/",
            // endpoint: 'http://127.0.0.1:5000/api/',
            liste_periode: [],
            liste_origine: [],
            liste_country: [],
            liste_database: [],
            liste_data_internal: [],
            liste_data_internal_total: [],
            email_admin: base64_decode(sessionStorage.getItem(base64_encode('email'))),
            pass_admin: null,
            country: "FR",
            periode: null,
            origine: null,
            origine_export: null,
            periode_export: null,

            loadingSubmit: false,
            displayLoginPanel: 'block',
            displayExportPanel: 'none',
            loadingBtnLogin: false,
            displayErrorLogin: 'none',
            field : {
                "idc": false,
                "datecollecte": true,
                "freshness": false,
                "origine": true,
                "ip": false,
                "urlcollecte": true,
                "civility": true,
                "firstname": true,
                "lastname": true,
                "adresse1": true,
                "addresse2": false,
                "zipcode": true,
                "city": true,
                "country": true,
                "email": true,
                "emailmd5": false,
                "mobile": true,
                "birthdate": true,
                "dob": false,
                "mob": false,
                "yob": false
            }

        }
    }

    componentDidMount() {
        this.getCountryandorigine()
        this.MonthSelector()
    }

    MonthSelector = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        const months = [];
        const monthsToDisplay = 11; // Including the current month and 10 previous months

        for (let i = monthsToDisplay - 1; i >= 0; i--) {
        const previousMonthDate = new Date(currentDate);
        previousMonthDate.setMonth(currentDate.getMonth() - i);
        const year = previousMonthDate.getFullYear();
        const month = previousMonthDate.getMonth() + 1;
        const monthString = `${year}-${String(month).padStart(2, '0')}`;
        months.push(monthString);
        }
        const current_period = currentYear+'-'+currentMonth
        this.setState({
            liste_periode: months,
            periode: current_period
        })

    }

    getCountryandorigine() {
        axios.get(this.state.endpoint+'internal/data')
            .then(res => {
                this.setState({
                    liste_country: res.data['country'],
                    liste_origine: res.data['origine'],
                })
            })
    }

    getInternalWs() {
        if (this.state.country === null || this.state.origine === null || this.state.periode === null) {
            if (this.state.country === null) { message.warning('No country selected !!!') }
            if (this.state.origine === null) { message.warning('No origine selected !!!') }
            if (this.state.periode === null) { message.warning('No period selected !!!') }
            return null
        } else {
            this.setState({ loadingSubmit: true })
            var fd = new FormData()
            fd.append('origine', this.state.origine)
            fd.append('country', this.state.country)
            fd.append('period', this.state.periode)
            axios.post(this.state.endpoint + 'internal/data/ws', fd)
                .then(res => {
                    console.log(res.data)
                    if (res.data === null){
                        message.info('No data received from this origine on this period !!!', 2)
                        this.setState({
                            loadingSubmit: false
                        })
                    }else{
                        this.setState({
                            liste_data_internal: res.data[0]['data'],
                            liste_data_internal_total: res.data[0]['agregate'],
                            loadingSubmit: false
                        })
                    }
                   
                })
        }
    }

    getAccessPanel() {
        if (this.state.email_admin === null || (this.state.email_admin).length === 0 || this.state.pass_admin === null || (this.state.pass_admin).length === 0) {
            message.warning('Incorrect credentials values !!!!', 2)
            return null
        } else {
            this.setState({ loadingBtnLogin: true })
            var fd = new FormData()
            fd.append('email', this.state.email_admin)
            fd.append('access_token', this.state.pass_admin)
            var url = this.state.endpoint + 'internal/admin/login'
            axios.post(url, fd)
                .then(res => {
                    if (res.data['status'] === 'error') {
                        this.setState({ loadingBtnLogin: false, loadingBtnLogin: false, displayErrorLogin: 'block', displayExportPanel: 'none' })
                    } else {
                        message.success('Welcome back', 1)
                        this.setState({ loadingBtnLogin: false, loadingBtnLogin: false, displayErrorLogin: 'none', displayExportPanel: 'block', displayLoginPanel: 'none', liste_database: res.data['data'] })
                    }
                })
        }
    }

    async onExportData(){
        if(this.state.origine_export === null || this.state.periode_export === null){
            message.warning("Please fill all fields !!!")
            return null
        }else{
            try{
                var fd = new FormData()
                fd.append("email", this.state.email_admin)
                fd.append("access_token", this.state.pass_admin)
                fd.append("origine", this.state.origine_export)
                fd.append("data", JSON.stringify(this.state.field))
                fd.append("period", this.state.periode_export)
                const response = await axios.post(this.state.endpoint+'internal/admin/export', fd, {
                    responseType: 'blob',
                  });
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));    
                    const link = document.createElement('a');
                    link.href = url;
                    var filename = this.state.origine_export + '_' + this.state.periode_export + '.csv'
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }else{
                    message.error('No data found for this origine', 2)
                    return null
                }
                
            } catch (error) {
                message.warning('Erreur lors du téléchargement du fichier :'+ error, 3);
            }
            
        }
    }

    render() {
        return (
            <div>
                <div style={{ padding: 5 }}>
                    <Row>
                        <Col span={11}>
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col span={2} style={{ textAlign: 'center' }}>
                                            <p>Country</p>
                                        </Col>
                                        <Col span={3}>
                                            <Select
                                                size="small"
                                                style={{ width: '100%', textAlign: 'center', fontWeight: 600, fontSize: 14 }}
                                                value={this.state.country}
                                                onChange={(value) => {
                                                    this.setState({country: value})
                                                }}
                                            >
                                                {
                                                    this.state.liste_country && this.state.liste_country.map(item => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={2} style={{ textAlign: 'center' }}>
                                            <p>Period</p>
                                        </Col>
                                        <Col span={5}>
                                            <Select
                                                size="small"
                                                style={{ width: '100%', textAlign: 'center', fontWeight: 600, fontSize: 14 }}
                                                value={this.state.periode}
                                                onChange={(value) => {
                                                    this.setState({periode: value})
                                                }}
                                            >
                                                {
                                                    this.state.liste_periode && this.state.liste_periode.map(item => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={2} style={{ textAlign: 'center' }}>
                                            <p>Origine</p>
                                        </Col>
                                        <Col span={4}>
                                            <Select
                                                size="small"
                                                style={{ width: '100%', textAlign: 'center', fontWeight: 600, fontSize: 14 }}
                                                value={this.state.origine}
                                                onChange={(value) => { this.setState({ origine: value }) }}

                                            >
                                                {
                                                    this.state.liste_origine && this.state.liste_origine.map(item => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={2} style={{ textAlign: 'center' }}>
                                            <Button
                                                loading={this.state.loadingSubmit}
                                                className='btn btn-success'
                                                style={{ fontSize: 12, height: 24, padding: 0, width: '100%' }}
                                                onClick={this.getInternalWs.bind(this)}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <div style={{ width: '100%', height: '68vh', overflow: 'auto' }}>
                                        <table className='table table-bordered'>
                                            <thead style={{ top: 0, zIndex: 1, position: 'sticky' }}>
                                                <tr>
                                                    <th rowSpan="2" style={{ textAlign: 'center', verticalAlign: 'middle', background: 'grey' }}>Date</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: '#439a00' }}>LIVE REQUEST</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: 'yellow' }}>WEBSERVICE</th>
                                                    <th rowSpan="2" style={{ textAlign: 'center', verticalAlign: 'middle', background: 'grey' }}> Emails nouveaux <br />[7]</th>
                                                    <th rowSpan="2" style={{ textAlign: 'center', verticalAlign: 'middle', background: 'grey' }}> Taux de transformation <br />[8]</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th style={{ background: '#439a00' }}>BRUT <br />[1]</th>
                                                    <th style={{ background: '#439a00' }}>NET <br />[2]</th>
                                                    <th style={{ background: '#439a00' }}>Taux de refus <br />[3]</th>
                                                    <th style={{ background: 'yellow' }}>BRUT <br />[4]</th>
                                                    <th style={{ background: 'yellow' }}>NET <br />[5]</th>
                                                    <th style={{ background: 'yellow' }}>Taux de refus <br />[6]</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: 15 }}>
                                                {
                                                    this.state.liste_data_internal && this.state.liste_data_internal.map(elem => {
                                                        return (
                                                            <tr key={elem['day']} style={{ textAlign: 'center' }}>
                                                                <td style={{ background: '#F5F5F5' }}>{elem['day']}</td>
                                                                <td style={{ background: '#E0FFE0' }} >{elem['live_brut']}</td>
                                                                <td style={{ background: '#E0FFE0' }} >{elem['live_accepted']}</td>
                                                                <td style={{ background: '#E0FFE0' }} >{elem['taux_d_refus_live']}</td>
                                                                <td style={{ background: '#FFFFE0' }} >{elem['ws_brut']}</td>
                                                                <td style={{ background: '#FFFFE0' }} >{elem['ws_accepted']}</td>
                                                                <td style={{ background: '#FFFFE0' }} >{elem['taux_d_refus_ws']}</td>
                                                                <td style={{ background: '#F5F5F5' }} >{elem['ws_accepted']}</td>
                                                                <td style={{ background: '#F5F5F5' }} >{elem['taux_d_transfo']}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.state.liste_data_internal_total && this.state.liste_data_internal_total.map(item => {
                                                        return (
                                                            <tr style={{ textAlign: 'center' }} >
                                                                <td style={{ background: '#F5F5F5' }}><strong>TOTAL</strong></td>
                                                                <td style={{ background: '#E0FFE0' }}><strong>{item['total_live_brut']}</strong></td>
                                                                <td style={{ background: '#E0FFE0' }}><strong>{item['total_live_accepted']}</strong></td>
                                                                <td style={{ background: '#E0FFE0' }}><strong>{item['total_taux_refus_live']}</strong></td>
                                                                <td style={{ background: '#FFFFE0' }}><strong>{item['total_ws_brut']}</strong></td>
                                                                <td style={{ background: '#FFFFE0' }}><strong>{item['total_ws_accepted']}</strong></td>
                                                                <td style={{ background: '#FFFFE0' }}><strong>{item['total_taux_refus_ws']}</strong></td>
                                                                <td style={{ background: '#F5F5F5' }}><strong>{item['total_ws_accepted']}</strong></td>
                                                                <td style={{ background: '#F5F5F5' }}><strong>{item['total_ws_accepted']}</strong></td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={12} style={{ height: '72vh', marginTop: '0px' }}>
                            <div style={{ border: '1px solid #23bcbe', width: '100%', height: '100%', overflow: 'auto' }}>
                                <div style={{ width: '100%', height: '4vh', background: '#23bcbe', textAlign: 'center', padding: 5 }}>
                                    <h4 style={{ color: '#fff' }}><strong>EXPORT TOOLS</strong></h4>
                                </div>

                                <div style={{ display: `${this.state.displayLoginPanel}`, width: '70%', margin: '100px auto', padding: 15 }}>
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'center', padding: 5 }}>
                                            <span>Username</span>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'center' }}>
                                            <Input
                                                size='medium'
                                                style={{ width: '100%', textAlign: 'center' }}
                                                value={this.state.email_admin}
                                                onChange={(e) => {
                                                    this.setState({
                                                        email_admin: e.target.value
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'center', padding: 5 }}>
                                            <span>Password</span>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'center' }}>
                                            <Input.Password
                                                size='medium'
                                                style={{ width: '100%', textAlign: 'center' }}
                                                value={this.state.pass_admin}
                                                onChange={(e) => {
                                                    this.setState({
                                                        pass_admin: e.target.value
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'center', padding: 5 }}>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'center' }}>
                                            <Button
                                                loading={this.state.loadingBtnLogin}
                                                className='btn btn-primary'
                                                style={{ width: '100%', height: 40 }}
                                                onClick={this.getAccessPanel.bind(this)}
                                            >
                                                SUBMIT
                                            </Button>
                                        </Col>
                                    </Row>
                                    <br />
                                    {
                                        this.state.displayErrorLogin === 'block' && <Row>
                                            <Col span={1}></Col>
                                            <Col span={19} style={{ textAlign: 'right', padding: 5, color: 'red', fontSize: 17 }}>
                                                <span>Authentification failed, please try again ! </span>
                                            </Col>
                                            <Col span={1}>
                                                <WarningOutlined style={{ fontSize: 18, color: 'red' }} />
                                            </Col>
                                        </Row>
                                    }
                                </div>

                                <div style={{ display: `${this.state.displayExportPanel}`, width: '100%', padding: 2 }}>
                                    <Row style={{ padding: 5 }}>
                                        <Col span={3} style={{ padding: 5 }}>Origine</Col>
                                        <Col span={8}>
                                            <Select
                                                size='small'
                                                showSearch
                                                style={{ width: '100%', textAlign: 'center' }}
                                                value={this.state.origine_export}
                                                onChange={(value) => {
                                                    this.setState({origine_export: value})
                                                }}
                                            >
                                                {
                                                    this.state.liste_origine && this.state.liste_origine.map(item => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={3} style={{ padding: 2, textAlign: 'center' }}>Period</Col>
                                        <Col span={8}>
                                            <Select
                                                size='small'
                                                style={{ width: '100%', textAlign: 'center', fontWeight: 600, fontSize: 14 }}
                                                value={this.state.periode_export}
                                                onChange={(value) => {
                                                    this.setState({periode_export: value})
                                                }}
                                            >
                                                {
                                                    this.state.liste_periode && this.state.liste_periode.map(item => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <hr style={{ color: '#23bcbe', height: 2 }} />
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <span style={{ textDecoration: 'underline' }}><strong>FIELDS TO EXPORT</strong></span>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col span={3} style={{ textAlign: 'center' }}>
                                            {/* <Checkbox
                                                // checked={false}
                                                onChange={(e) => {
                                                    var old = this.state.field
                                                    old.map(item => {
                                                        old[item] = e.target.checked
                                                    })
                                                    this.setState({ field: old })
                                                }}
                                            >
                                                All
                                            </Checkbox> */}
                                        </Col>
                                        <Col span={20} style={{ textAlign: 'center' }}>
                                            <Row>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox 
                                                        checked={(this.state.field)['idc']} 
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['idc'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        IDC (identifiant)
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['datecollecte']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['datecollecte'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Date collecte
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['freshness']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['freshness'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Date fraicheur
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['origine']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['origine'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Origine
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['ip']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['ip'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        IP
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['urlcollecte']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['urlcollecte'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        URL collecte
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['civility']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['civility'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Civility
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['firstname']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['firstname'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Firstname
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['lastname']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['lastname'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Lastname
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['adresse1']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['adresse1'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Adresse1
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['addresse2']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['addresse2'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Adresse2
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox 
                                                        checked={(this.state.field)['zipcode']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['zipcode'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Zipcode
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['city']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['city'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        City
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['country']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['country'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Country
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['email']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['email'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Email
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['emailmd5']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['emailmd5'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        EmailMD5
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['mobile']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['mobile'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Mobile
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['birthdate']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['birthdate'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        Birthdate
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['dob']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['dob'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        DOB
                                                    </Checkbox>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['mob']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['mob'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        MOB
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={(this.state.field)['yob']}
                                                        onChange={(e) => {
                                                            var old = this.state.field
                                                            old['yob'] = e.target.checked
                                                            this.setState({ field: old })
                                                        }}
                                                    >
                                                        YOB
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr style={{ color: '#23bcbe', height: 2 }} />
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                    <Button
                                        className='btn btn-success'
                                        onClick={this.onExportData.bind(this)}
                                        style={{width: '35%', height: 36}}
                                    >
                                        SUBMIT
                                    </Button>
                                    </div>
                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Internal;
