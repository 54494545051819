import React, { Component } from 'react';
import { Collapse, Button, Checkbox, Col, Input, message, Row, Select, Tooltip } from 'antd';
import Internal from './Internal';

const { Panel } = Collapse;

class Webservices extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {

        return (
            <div >
                <Row>
                    <Col span={24}>
                    <div style={{height: '90vh'}}>
                        <Collapse accordion  defaultActiveKey={['1']}>
                        <Panel header={<span style={{ fontWeight: 700 }}>INTERNAL</span>} key="1" style={{ height: '100%' }}>
                            <div style={{ height: '74vh', overflow: 'auto'}}>
                                <Internal/>
                            </div>
                        </Panel>
                        <Panel header={<span style={{ fontWeight: 700 }}>EXTERNAL</span>} key="2" style={{ height: '100%' }}>
                            <div style={{ height: '74vh'}}>

                            </div>
                        </Panel>
                        </Collapse>
                    </div>
                    </Col>
                    {/* <Col span={12}></Col> */}
                </Row>
            </div>
        );
    }
}

export default Webservices;