import Login from './Login/Login';

function App() {
  return (
    <div className="App">
        <Login />
    </div>
  );
}

export default App;
