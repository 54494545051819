import React, {useState, useEffect} from 'react';
import { useHistory} from 'react-router-dom'
import { base64_encode } from '../Utilities/Base64';
import './Login.css'
import { Button, message} from 'antd'
import axios from 'axios';

export default function Login() {
	const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessageShow, setErrorMessageShow] = useState('none');
    const history = useHistory()

	useEffect(() => {
        if(sessionStorage.getItem(base64_encode('session')) !== null){
            history.push('/home')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onLogin(e)
        }
    }

	const handleInputEmail = (e) => {
        setEmail(e.target.value)
    }	

	const onLogin = (e) => {
        e.preventDefault()
        if(email === null || password === null || email.length === 0 || password.length ===0){
            message.warning('Authentification failed, Please try again !!', 1)
            return 
        }else{
            setLoading(true)
            var fd = new FormData()
            fd.append('email', email)
            fd.append('password', password)
            axios.post(process.env.REACT_APP_ENDPOINT_AUTHENTIFICATION, fd)
            .then(res => {
                if(res.data['status'] === "error"){
                    setErrorMessageShow('block')
                    setLoading(false)
                    return
                }else{
                    sessionStorage.setItem(base64_encode('session'), '1')
                    sessionStorage.setItem(base64_encode('userconnected'), base64_encode(res.data['user']))
                    sessionStorage.setItem(base64_encode('email'), base64_encode(email))

                    setLoading(false)
                    history.push('/home')
                }
            })
        }
    }



  return (
    <div className="login-box">
        <form>
            <div className="user-box">
                <input 
                    type="text" 
                    name="" 
                    required
                    onChange={handleInputEmail}
                />
                <label>Your email address</label>
            </div>
            <div className="user-box">
                <input 
                    type="password" 
                    autoComplete='current-password' 
                    required
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    onKeyDown={handleKeyDown}
                />
                <label htmlFor="">Password</label>
            </div>
            <Button 
                loading={loading}
                className='btn btn-success' 
                type="primary"
                onClick={onLogin}
            >
                LOGIN
            </Button>
            <br/><br/>
            <div className='error-box' style={{display: `${errorMessageShow}`}}>
                <h4>Authentification failed</h4>
            </div>
        </form>
    </div>
  )
}
